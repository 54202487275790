<template>
  <div>
    <IbTabs />
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-col
            v-for="key in Object.keys(dashboard)"
            :key="dashboard[key].id"
            xl="3"
            lg="6"
            sm="12"
          >
            <statisticCard
              :client="dashboard[key]"
              :show="false"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <div
          v-b-visible.once="(e)=>callGetChartDataFunction(e,'commissions')"
          class="d-flex"
          style="gap: 16px;"
        >
          <h3>{{ $t('commissions') }}</h3>
          <b-spinner
            v-if="chartData.commissions.loading"
            label="Spinning"
          />
        </div>
        <b-row>
          <b-col>
            <b-row class="m-0">
              <b-button
                v-b-modal="'modal-1'"
                size="sm"
                class="durationBtn"
                @click="()=> {
                  durationChartName='commissions'
                }"
              >
                {{ $t('date') }}
              </b-button>
            </b-row>
            <LineChart
              ref="lineChart"
              :chart-data="chartData.commissions"
              class="mb-5"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    ticks: {
                      precision: 0,
                      maxRotation: 90,
                    },
                    suggestedMin: 0,
                    suggestedMax: Math.max(...[...chartData.new_users.datasets[0].data]) + 10,
                  },
                  x:{
                    ticks:{
                      font:{
                        family:'pelak'
                      }
                    }
                  }
                },
                interaction: {
                  intersect: false,
                },
                plugins: {
                  legend:{
                    labels: {
                      font: {
                        family: 'pelak'
                      }
                    }
                  }
                }
              }"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <b-card
            style="height: calc(100% - 32px);"
          >
            <div
              v-b-visible.once="(e)=>callGetChartDataFunction(e,'active_users')"
              class="d-flex"
              style="gap: 16px;"
            >
              <h3>{{ $t('active_users') }}</h3>
              <b-spinner
                v-if="chartData.active_users.loading"
                label="Spinning"
              />
            </div>
            <DoughnutChart
              ref="doughnutChart"
              :chart-data="chartData.active_users"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    callbacks: {
                      label(context) {
                        const label = context.label || ''
                        return [`${label.user.first_name} ${label.user.last_name}`, label.user.email, `trade: ${toFixed(label.traded,2)} lots`]
                      },
                    },
                  },
                  legend: {
                    display: true,
                    labels: {
                      font: {
                        family:'Pelak'
                      },
                      generateLabels(chart) {
                        return chart.data.labels.map((label, index) => ({
                          text: `${label.user.first_name} ${label.user.last_name}`,
                          fillStyle: chart.data.datasets[0].backgroundColor[index],
                        }))
                      },
                    },
                  },
                },
              }"
            />
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <b-card
            style="height: calc(100% - 32px);"
          >
            <div
              v-b-visible.once="(e)=>callGetChartDataFunction(e,'trades')"
              class="d-flex"
              style="gap: 16px;"
            >
              <h3>{{ $t('trades_per_token') }}</h3>
              <b-spinner
                v-if="chartData.trades.loading"
                label="Spinning"
              />
            </div>
            <DoughnutChart
              ref="doughnutCharttrades"
              :chart-data="chartData.trades"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      font: {
                        family:'Pelak'
                      },
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label(context) {
                        const label = context.label || ''
                        return [ `${label}: ${toFixed(context.formattedValue,2)} lots`]
                      },
                    },
                  },
                }
              }"
            />
          </b-card>
        </b-col>
        <b-col
          cols="12"
          lg="12"
        >
          <b-card
            style="height: calc(100% - 32px);"
          >
            <div
              v-b-visible.once="(e)=>callGetChartDataFunction(e,'new_users')"
              class="d-flex"
              style="gap: 16px;"
            >
              <h3>{{ $t('new_users') }}</h3>
              <b-spinner
                v-if="chartData.new_users.loading"
                label="Spinning"
              />
            </div>
            <b-row class="m-0">
              <b-button
                v-b-modal="'modal-1'"
                size="sm"
                class="durationBtn"
                @click="()=> {
                  durationChartName='new_users'
                }"
              >
                {{ $t('date') }}
              </b-button>
            </b-row>
            <LineChart
              ref="lineChart"
              :chart-data="chartData.new_users"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    ticks: {
                      precision: 0,
                      maxRotation: 90,
                    },
                    suggestedMin: 0,
                    suggestedMax: Math.max(...[...chartData.new_users.datasets[0].data]) + 3,
                  },
                  x:{
                    ticks:{
                      font:{
                        family:'pelak'
                      }
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      font: {
                        family:'Pelak'
                      },
                    },
                  },
                },
                interaction: {
                  intersect: false,
                },
              }"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col>
          <b-card
            style="height: 100%;"
          >
            <div
              v-b-visible.once="(e)=>callGetChartDataFunction(e,'equity_balance')"
              class="d-flex"
              style="gap: 16px;"
            >
              <h3>{{ $t('equity_vs_balance') }}</h3>
              <b-spinner
                v-if="chartData.equity_balance.loading"
                label="Spinning"
              />
            </div>
            <b-row class="m-0">
              <b-button
                v-b-modal="'modal-1'"
                size="sm"
                class="durationBtn"
                @click="()=> {
                  durationChartName='equity_balance'
                }"
              >
                {{ $t('date') }}
              </b-button>
            </b-row>
            <LineChart
              ref="lineChart"
              :chart-data="chartData.equity_balance"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    ticks: {
                      precision: 0,
                      maxRotation: 90,
                    },
                    suggestedMin: 0,
                    suggestedMax: Math.max(...[...chartData.new_users.datasets[0].data]) + 10,
                  },
                  x:{
                    ticks:{
                      font:{
                        family:'pelak'
                      }
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      font: {
                        family:'Pelak'
                      },
                    },
                  },
                },
                interaction: {
                  intersect: false,
                },
              }"
            />
          </b-card>
        </b-col> -->
        <b-col>
          <b-card
            style="height: 100%;"
          >
            <div
              v-b-visible.once="(e)=>callGetChartDataFunction(e,'deposit_withdrawal')"
              class="d-flex"
              style="gap: 16px;"
            >
              <h3>{{ $t('deposit_vs_withdraw') }}</h3>
              <b-spinner
                v-if="chartData.deposit_withdrawal.loading"
                label="Spinning"
              />
            </div>
            <b-row class="m-0">
              <b-button
                v-b-modal="'modal-1'"
                size="sm"
                class="durationBtn"
                @click="()=> {
                  durationChartName='deposit_withdrawal'
                }"
              >
                {{ $t('date') }}
              </b-button>
            </b-row>
            <BarChart
              ref="barChartDW"
              :chart-data="chartData.deposit_withdrawal"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal
      id="modal-1"
      hide-footer
      @hidden="()=>{
        durationDate=''
      }"
    >
      <b-form @submit.prevent="onDurationChange( durationBtnSelected,durationChartName,durationDate)">
        <b-row style="gap: 5px;">
          <b-button
            v-for="duration in durations"
            :key="duration + 'durationbtneKey'"
            size="sm"
            :class="duration === durationBtnSelected ? 'durationBtnUnselected' : 'durationBtn'"
            @click="(()=>{
              durationBtnSelected = duration
            })"
          >
            {{ $t(duration) }}
          </b-button>
        </b-row>
        <b-form-group
          :label="$t('date')"
          label-for="date"
        >
          <date-picker
            id="date"
            v-model="durationDate"
            :type="durationBtnSelected=='monthly'?'year':(durationBtnSelected=='weekly'?'year-month':'year')"
            locale="en"
            :format="durationBtnSelected=='monthly'?'YYYY':'YYYY-MM'"
            :max="(new Date())"
            auto-submit
            clearable
            :disabled="durationBtnSelected=='yearly'"
            @year-change="onYearChange"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
        >
          {{ $t('submit') }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BIconBookmarkCheck, BIconCartDash, BIconCalculator, BIconPerson,
} from 'bootstrap-vue'
import moment from 'moment'
import DatePicker from 'vue-persian-datetime-picker'
import LineChart from '@/modules/IB/views/LineChart.vue'
import BarChart from '@/modules/IB/views/components/BarChart.vue'
import IbTabs from './components/ibTabs.vue'
import DoughnutChart from '@/modules/IB/views/DoughnutChart.vue'
import statisticCard from '@/modules/IB/views/components/statisticCard.vue'
import PannelIbApis from '@/modules/IB/services/apis/index'
import ProfileApis from '@/services/apis/profile'
import { toFixed } from '@/utils'

const profile = new ProfileApis()
const IB = new PannelIbApis()
export default {
  components: {
    BCard,
    LineChart,
    BarChart,
    DoughnutChart,
    BRow,
    BCol,
    IbTabs,
    statisticCard,
    DatePicker,
  },
  data() {
    return {
      show: true,
      toFixed,
      dashboardData:
      {
        clients: {
          title: this.$t('clients'),
          text: '000',
          icon: BIconPerson,
          color: '#cd4dff',
        },
        // equity: {
        //   title: this.$t('forex.equity'),
        //   text: '000',
        //   icon: BIconBookmarkCheck,
        //   color: '#ff9700',
        // },
        lots: {
          title: this.$t('traded_lots'),
          text: '000',
          icon: BIconCartDash,
          color: '#8c76fd',
        },
        commissions: {
          title: this.$t('total-commission'),
          text: '000',
          icon: BIconCalculator,
          color: '#50adff',
        },
      },
      moment,
      durations: [
        'weekly', 'monthly', 'yearly',
      ],
      durationChartName: '',
      durationDate: '',
      durationBtnSelected: 'monthly',
      activeRequest: [],
      chartData: {
        new_users: {
          isCalledOnceOnvisible: false,
          async  getData(period, date, options) {
            this.chartData.new_users.loading = true
            const expectedLabels = this.generateLables(period)
            // Fetch the data from the API
            await IB.chartDepositWithdrawal('new_users', { period, date }, options).then(res => {
              const Fdata = this.proccessChartData(period, expectedLabels,
                [
                  res.data.results.map(item => ({ value: item.total_user, date: item.date })),
                ])
              // Update the chart data
              this.chartData.new_users.labels = res.data.results.map(item => moment(item.date).format('YYYY-MMM'))
              this.chartData.new_users.datasets.forEach((dataset, index) => {
                // eslint-disable-next-line no-param-reassign
                dataset.data = Fdata[index]
              })
            }).finally(() => {
              this.chartData.new_users.loading = false
            })
          },
          labels: [],
          loading: false,
          datasets: [
            {
              label: this.$t('registred_clients'),
              data: [],
              backgroundColor: 'white',
              borderColor: '#C893FD',
              cubicInterpolationMode: 'monotone',
              pointStyle: 'circle',
              pointRadius: 4,
              fill: false,
            },
          ],
        },
        commissions: {
          async getData(period, date, options) {
            this.chartData.commissions.loading = true
            const expectedLabels = this.generateLables(period)
            // Fetch the data from the API
            await IB.chartDepositWithdrawal('commissions', { period, date }, options).then(res => {
              const Fdata = this.proccessChartData(period, expectedLabels,
                [
                  res.data.results.map(item => ({ value: item.total_commission, date: item.date })),
                ])
              // Update the chart data
              this.chartData.commissions.labels = res.data.results.map(item => moment(item.date).format('YYYY-MMM'))
              this.chartData.commissions.datasets.forEach((dataset, index) => {
                // eslint-disable-next-line no-param-reassign
                dataset.data = Fdata[index]
              })
            }).finally(() => {
              this.chartData.commissions.loading = false
            })
          },
          duration: 'daily',
          labels: [],
          loading: false,
          datasets: [
            {
              label: this.$t('commissions'),
              data: [],
              backgroundColor: 'white',
              borderColor: '#f87979',
              cubicInterpolationMode: 'monotone',
              pointStyle: 'circle',
              pointRadius: 4,
              fill: false,
            },
          ],
        },
        // equity_balance: {
        //   isCalledOnceOnvisible: false,
        //   async  getData(period, date, options) {
        //     this.chartData.equity_balance.loading = true
        //     const expectedLabels = this.generateLables(period)
        //     // Fetch the data from the API
        //     await IB.chartDepositWithdrawal('equity_balance', { period, date }, options).then(res => {
        //       const Fdata = this.proccessChartData(period, expectedLabels,
        //         [
        //           res.data.results.map(item => ({ value: item.total_equity, date: item.date })),
        //           res.data.results.map(item => ({ value: item.total_balance, date: item.date })),
        //         ])
        //       // Update the chart data
        //       this.chartData.equity_balance.labels = res.data.results.map(item => moment(item.date).format('YYYY-MMM'))
        //       this.chartData.equity_balance.datasets.forEach((dataset, index) => {
        //         // eslint-disable-next-line no-param-reassign
        //         dataset.data = Fdata[index]
        //       })
        //     }).finally(() => {
        //       this.chartData.equity_balance.loading = false
        //     })
        //   },
        //   labels: [],
        //   loading: false,
        //   datasets: [
        //     {
        //       label: this.$t('total_equity'),
        //       data: [],
        //       backgroundColor: 'white',
        //       borderColor: '#FF718B',
        //       cubicInterpolationMode: 'monotone',
        //       pointStyle: 'circle',
        //       pointRadius: 4,
        //       fill: false,
        //     },
        //     {
        //       label: this.$t('total_balance'),
        //       data: [],
        //       backgroundColor: 'white',
        //       borderColor: '#962DFF',
        //       cubicInterpolationMode: 'monotone',
        //       pointStyle: 'circle',
        //       pointRadius: 4,
        //       fill: false,
        //     },
        //   ],
        // },
        deposit_withdrawal: {
          isCalledOnceOnvisible: false,
          async  getData(period, date, options) {
            this.chartData.deposit_withdrawal.loading = true
            const expectedLabels = this.generateLables(period)
            // Fetch the data from the API
            await IB.chartDepositWithdrawal('deposit_withdrawal', { period, date }, options).then(res => {
              const Fdata = this.proccessChartData(period, expectedLabels,
                [
                  res.data.results.map(item => ({ value: item.total_withdrawal, date: item.date })),
                  res.data.results.map(item => ({ value: item.total_deposit, date: item.date })),
                ])
              // Update the chart data
              this.chartData.deposit_withdrawal.labels = res.data.results.map(item => moment(item.date).format('YYYY-MMM'))
              this.chartData.deposit_withdrawal.datasets.forEach((dataset, index) => {
                // eslint-disable-next-line no-param-reassign
                dataset.data = Fdata[index]
              })
            }).finally(() => {
              this.chartData.deposit_withdrawal.loading = false
            })
          },
          labels: [],
          loading: false,
          datasets: [
            {
              type: 'bar',
              label: this.$t('withdrawal'),
              data: [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: '#962DFF',
              borderRadius: 8,
            },
            {
              type: 'bar',
              label: this.$t('deposit'),
              data: [],
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: '#FF2D79',
              borderRadius: 4,
            },
          ],
        },
        active_users: {
          async   getData(period, date, options) {
            this.chartData.active_users.loading = true
            this.chartData.active_users.labels = []
            this.chartData.active_users.datasets[0].data = []
            await IB.chartDepositWithdrawal('active_users', { period: 'year', date }, options).then(res => {
              res.data.results.forEach(item => {
                const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`
                this.chartData.active_users.labels.push(item)
                this.chartData.active_users.datasets[0].data.push(toFixed(item.traded, 2))
                this.chartData.active_users.datasets[0].backgroundColor.push(color)
              })
            }).finally(() => {
              this.chartData.active_users.loading = false
            })
          },
          labels: [
          ],
          datasets: [{
            label: 'active_users',
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }],
          loading: false,
        },
        trades: {
          isCalledOnceOnvisible: false,
          async  getData(period, date, options) {
            this.chartData.trades.loading = true
            this.chartData.trades.labels = []
            this.chartData.trades.datasets[0].data = []
            await IB.chartDepositWithdrawal('trades', { period: 'year', date }, options).then(res => {
              res.data.results.forEach(item => {
                const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`
                this.chartData.trades.labels.push(item.symbol)
                this.chartData.trades.datasets[0].data.push(toFixed(item.traded, 2))
                this.chartData.trades.datasets[0].backgroundColor.push(color)
              })
            }).finally(() => {
              this.chartData.trades.loading = false
            })
          },
          labels: [
          ],
          datasets: [{
            label: 'trades',
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }],
          loading: false,
        },
      },
    }
  },
  computed: {
    dashboard() {
      return this.dashboardData
    },
    showIbImportantNote() {
      return process.env.VUE_APP_IB_IMPORTANT_NOTE === 'true'
    },
  },
  watch: {
    '$i18n.locale': {
      async handler() {
        moment.locale(this.$i18n.locale)
        Object.keys(this.chartData).forEach(async item => {
          await (this.chartData[item].getData.bind(this))('monthly')
        })
      },
    },
  },
  async mounted() {
    moment.locale(this.$i18n.locale)
    profile.get()
    this.getData()
  },
  methods: {
    async callGetChartDataFunction(isVisible, chartName) {
      if (isVisible && !this.chartData[chartName].isCalledOnceOnvisible) {
        this.chartData[chartName].isCalledOnceOnvisible = true
        console.log(isVisible, chartName)
        await (this.chartData[chartName].getData.bind(this))('monthly', undefined)
      }
    },
    getData() {
      this.show = true
      IB.dashboard().then(res => {
        this.dashboardData.commissions.text = res.data.results.total_commission.toFixed(2)
        this.dashboardData.clients.text = res.data.results.total_client
        this.dashboardData.equity.text = res.data.results.total_equity.toFixed(2)
        this.dashboardData.lots.text = res.data.results.total_lot.toFixed(2)
      }).finally(() => {
        this.show = false
      })
    },

    onDurationChange(duration, chartName, date) {
      this.chartData[chartName].duration = duration
      if (duration === 'custom' && !date) {
        return
      }
      if (duration === 'custom' && date) {
        (this.chartData[chartName].getData.bind(this))(undefined, date)
        return
      }
      (this.chartData[chartName].getData.bind(this))(duration, duration !== 'yearly' ? date : undefined)
    },
    formatLablesString(period) {
      // Determine the format string based on the period
      // eslint-disable-next-line no-nested-ternary
      const formatString = period === 'monthly' ? 'YYYY-MM' : (period === 'yearly' ? 'YYYY' : 'YYYY-MM-DD')
      return formatString
    },
    generateLables(period) {
      // Determine the number of labels to generate
      // eslint-disable-next-line no-nested-ternary
      const numberOfLabels = period === 'monthly' ? 12 : (period === 'yearly' ? 10 : (period === 'weekly' ? 5 : 1))

      // Generate labels starting from today and going back into the past
      const expectedLabels = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < numberOfLabels; i++) {
        // eslint-disable-next-line no-nested-ternary
        const label = moment().subtract(i, period === 'monthly' ? 'months' : (period === 'yearly' ? 'years' : 'weeks')).format(this.formatLablesString(period))
        // Add the generated label to the beginning of the array to ensure labels are in reverse chronological order
        expectedLabels.unshift(label)
      }
      return expectedLabels
    },
    proccessChartData(period, expectedLabels, args) {
      return args.map(item => item.map(i2 => i2.value))
    },
    onYearChange(e) {
      if (this.durationBtnSelected === 'monthly') {
        setTimeout(() => {
          this.durationDate = moment(e._d).format('YYYY')
        }, 200)
      }
    },
  },
}
</script>

<style>
.durationBtn {
  background-color: #393939 !important;
}

.durationBtnUnselected {
  background-color: #626262 !important;
}
</style>
