<template>
  <Bar
    v-if="Object.keys(chartData).length > 0"
    ref="barChart"
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },

  },
  data() {
    return {

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              precision: 0,
              maxRotation: 90,
            },
            suggestedMax: Math.max(...[...this.chartData.datasets[0].data, ...this.chartData.datasets[1].data]) + 3,
          },
          x: {
            ticks: {
              font: {
                family: 'pelak',
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              font: {
                family: 'Pelak',
              },
            },
          },
        },
      },
    }
  },
  watch: {
    chartData: {
      handler() {
        this.$refs.barChart.renderChart(this.chartData, this.chartOptions)
      },
      deep: true,
    },
  },
}
</script>
